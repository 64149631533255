.flex-column {
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}

.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}
.sortable {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding: 0;
    box-sizing: border-box;
}
.sortable li {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 4px;
    box-sizing: border-box;
}
.sortable li:first-child {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
.studio-image-container {
    background-color: #d4d4d4;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
    border-radius: 6px;
}