body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-datepicker__input-container input, .react-datepicker__input-container input:focus,.react-datepicker__input-container input:hover, .react-datepicker__input-container input:visited, .react-datepicker__input-container input:active {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  font-size: 16px;
  width: 90px;
}
.react-confirm-alert-overlay {
  z-index: 9999!important;
}